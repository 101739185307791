<template>
  <div class="container">
    <div class="header-container">
      <header>
        <van-nav-bar
            title="账单详情"
            left-text="返回"
            right-text=""
            left-arrow
            @click-left="onBack"
        />
      </header>
    </div>
    <div class="section-box">
      <div class="main-top" style="background:#fff">
        <div class="main-top-div1">户号：{{bindInfo.accountNo}}</div>
        <div class="main-top-div2">户名：{{bindInfo.accountName}}</div>
        <div class="main-top-div3">地址：{{bindInfo.address}}</div>
      </div>
    </div>
    <table class="bill-base-detail">
      <tr>
        <th>账单月份</th>
        <td>{{billDetail.C166}}</td>
      </tr>
      <tr>
        <th>上次抄表</th>
        <td>{{billDetail.C049}}</td>
      </tr>
      <tr>
        <th>本次抄表</th>
        <td>{{billDetail.C050}}</td>
      </tr>
      <tr>
        <th>上次到度</th>
        <td>{{billDetail.C057}}立方米</td>
      </tr>
      <tr>
        <th>本次到度</th>
        <td>{{billDetail.C058}}立方米</td>
      </tr>
      <tr>
        <th>抄表情况</th>
        <td>{{billDetail.C056}}</td>
      </tr>
      <tr>
        <th>用水量</th>
        <td>{{billDetail.C002}}立方米</td>
      </tr>
      <tr>
        <th>未出账水量</th>
        <td>{{billDetail.C466}}立方米</td>
      </tr>
      <tr>
        <th>自来水费</th>
        <td>{{billDetail.C113}}元</td>
      </tr>
      <tr>
        <th>污水费</th>
        <td>{{billDetail.C115}}元</td>
      </tr>
      <tr>
        <th>垃圾费</th>
        <td>{{billDetail.C310}}元</td>
      </tr>
      <tr>
        <th>违约金</th>
        <td>{{billDetail.C311}}元</td>
      </tr>
      <tr>
        <th>账单说明</th>
        <td>{{billDetail.C454}}</td>
      </tr>
      <tr>
        <th>合计金额</th>
        <td style="color:#1989fa;font-weight:800;font-size:100%">{{billDetail.C291}}元</td>
      </tr>
      <tr>
        <th>交费状态</th>
        <td style="color:#1989fa;font-weight:800;font-size:100%">{{billDetail.C168}}   <van-button v-if="billDetail.C168!='已交费'" type="info" hairline size="small" style="position:absolute;float:right;right:10px;"  @click="toSettlement">去交费</van-button></td>
      </tr>
      <tr v-if="billDetail.C168=='已交费'">
        <th>交费日期</th>
        <td>{{billDetail.C095}}</td>
      </tr>
      <tr v-if="billDetail.C168=='已交费'">
        <th>交费渠道</th>
        <td>{{billDetail.C098}}</td>
      </tr>
      <tr v-if="billDetail.C168=='已交费'">
        <th>交费方式</th>
        <td>{{billDetail.C097}}</td>
      </tr>
    </table>
    <div style="position:relative;margin-bottom:20px;">
      <ul class="message-ul">
  	  	<li class="title">温馨提示：</li>
  	  	<li>若您对账单信息有疑问，请咨询客服热线！</li>
  	  </ul>
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "../../components/logding";
import {getKey} from "../../utils/cookie";
import {getInfo, getBillDetail,payDemo} from "../../api/billSearch";
export default {
  name: "index",
  components:{Loading},
  data(){
    return{
      isShow : true,
      lodingTxt: '加载中...',
      accountNo: '',
      isLogin: false,
      bindInfo:{},
      billDetail:{},
    }
  },
  created() {
    //重新获取登录状态
    const isLogin = (getKey(process.env.VUE_APP_H5_IS_LOGIN) || 'false') == 'true' ? true : false
    this.isLogin = isLogin
    console.log('islogin='+isLogin)
    if (isLogin){
      const userinfo = JSON.parse(getKey(process.env.VUE_APP_USER_INFO) || '{}')
      if (userinfo && userinfo.account){
        //获取选中的绑定的户号
        const bindIndex = parseInt(getKey(process.env.VUE_APP_H5_BIND_CHECK_INDEX) || '0')
        console.log('bindIndex='+bindIndex)
        let i = 0
        userinfo.waterUserList.forEach(item => {
          if (i == bindIndex){
            console.log(item)
            this.bindInfo = item
          }
          let account={}
          account.text=item.accountNo+"【"+item.address+"】";
          account.value=item.accountNo;
          i ++;
        })
      }
      this.queryBillDetail();
    }
  },
  methods:{
    onBack : function (){
      this.$router.go(-1);
    },
    onChange(event){
      console.log(JSON.stringify(event));
      this.indexA=true;
      this.indexB=true;
    },
    toSettlement:function(){
      const that=this;
      let queryPar={}
      queryPar.billNoSet=that.billDetail.C426;
      this.$router.push({path:'../payment/settlement',query:queryPar})
    },
    queryBillDetail(){
      //console.log("账单详情查询参数:"+JSON.stringify(this.$route.query))
      const that=this;
      that.isShow=true;
      that.defaultChecked=false;
      that.bindInfo=this.$route.query;
      const accountNo=that.bindInfo.accountNo;
      if(accountNo==""||accountNo=="undefined")return;
      getBillDetail(accountNo,that.bindInfo.billNo).then(response => {
        that.isShow=false;
        //console.log("账单详情查询结果:"+JSON.stringify(response))
        that.billDetail = response.data.billDetail;
        if(that.billDetail==null){
          that.$toast('未查询到账单');
        }
      }).catch(() => {
        that.isShow=false;
        that.$toast('查询账单出错，请稍后再试');
      });
    },
    onBindAccount(){}
  }
}
</script>

<style scoped>
.main-top{padding: 0px; border-bottom:1px solid #ebebeb; color: black;}
.main-top-div1{
  height:40px;
  padding:5px 10px 5px 10px;
  color:#323233;
  font-size:100%;
  line-height:40px;
  font-weight:normal;
}

.main-top-div2{
  height:40px;
  padding:0px 10px 5px 10px;
  color:#969799;
  font-size:100%;
  line-height:40px;
  font-weight:normal;
}

.main-top-div3{
  padding:5px 10px 5px 10px;
  color:#969799;
  font-size:100%;
  line-height:20px;
  font-weight:normal;
}
.van-checkbox{
  margin-top:6px;
  margin-bottom:0px;
}

.van-checkbox span{
    width:1000px!import;
}

.van-dropdown-menu__bar--opened {text-align: left;background: #db2828!important; color: #fff}
.van-dropdown-item__option--active{background: #db2828!important; color: #db2828;}

.van-dropdown-menu__title{
  font-size:12px!important;
}
.1van-dropdown-menu{
  background:red;
  height:100px;
}

.1van-dropdown-menu__bar{
  margin-bottom:100px;
  background:green;
  height:120px ! important;
}

.1van-dropdown-menu__item{
  height:120px ! important;
}

.label-class-1{
  width:100%!import;
}

.bill-base-detail {
  width: 100%;
  font-size: 100%;
  color: #515151;
  border:0px solid #fff;
}

.bill-base-detail th {
  height: 30px;
  width:120px;
  line-height: 30px;
  text-align: right;
  padding-right:10px;
  //background: #aaaaaa;
  background: #fff;
  font-weight:600!important;
}

.bill-base-detail td {
  padding:5px 10px 5px 5px;
  border-bottom:1px dashed #ddd;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #fff;
}

.bill-base-detail td input{
  height:18px;
  width:18px;
}
.message-ul{
	margin:10px 2px 20px 2px;
	padding:10px;
	line-height:20px;
	background:#eee;
}
.message-ul li{
	font-size:14px;
}

.message-ul .title{
	font-weight:bold;
  line-height:30px;
}
</style>
</style>
